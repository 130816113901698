@use "../abstract/variables" as var
@use "../abstract/mixins" as mixin

.paginator
    display: grid
    grid-auto-flow: column
    column-gap: var.$gap__s
    width: max-content
    max-width: 100%
    @include mixin.centerX

    &__prev,
    &__next,
    &__number
        display: flex
        align-items: center
        justify-content: center
        transition: var.$transition__main

        &:hover,
        &:active
            color: var.$color__orange

    &__prev,
    &__next
        font-size: 1rem
        padding:
            top: var.$gap__xxs
            bottom: var.$gap__xxs

    &__prev
        padding-right: var.$padding__xxxs

    &__next
        padding-left: var.$padding__xxxs

    &__numbers
        display: flex
        flex-wrap: wrap
        justify-content: center

        & > button:last-child > span
            display: none

    &__number
        font-size: 1.1rem
        padding: var.$gap__xxs var.$gap__xs

        &:disabled
            font-size: 1.6rem
            font-weight: 600

            &:hover,
            &:active
                color: unset
                cursor: not-allowed
