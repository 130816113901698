@use "../abstract/variables" as var
@use "../abstract/mixins" as mixin

.select-main
    position: relative
    display: grid
    grid-template-columns: 1fr
    row-gap: var.$gap__xs

    &__header
        display: grid
        grid-template-columns: auto 1fr
        column-gap: var.$gap__xs
        align-items: baseline

        .icon__property,
        .icon__province
            font-size: 1rem

        .icon__location
            font-size: 1.05rem

    &__content-wrapper
        position: relative
        height: var.$button-size__s
        overflow: visible
        transition: var.$transition__main
        z-index: 1

        &.hover
            z-index: 100

            & > label,
            & > div

                & > input
                    outline-color: var.$color__orange

                & > span > i
                    color: var.$color__orange
                    transform: scaleY(-1)

            & > div
                max-height: calc(4.5rem * 3)

    .input-label__main
        position: relative
        background-color: var.$color__white
        z-index: 1

        & > input
            height: calc(#{var.$button-size__s} - (#{var.$padding__xxs} * 2))
            padding-right: calc(#{var.$el-size__xs} - #{var.$padding__xxs})

            &:focus::placeholder
                opacity: 1

        & > span:last-child
            position: absolute
            right: 0
            @include mixin.flex(center, center, column)
            height: 100%
            width: var.$el-size__xs
            font-size: .5rem

    .list__main-wrapper
        top: calc(100% + 1px)
        width: 100%
        height: max-content
        max-height: 0
        background-color: var.$color__white

    .list__main,
    .list__main-django
        height: max-content
        max-height: inherit
        overflow: auto

        /* Change scroll bar appearance */
        /* Works on Firefox */
        scrollbar-width: thin
        scrollbar-color: var.$color__pale-orange var.$color__white

        /* Works on Chrome, Edge, and Safari */
        &::-webkit-scrollbar
            width: 12px

        &::-webkit-scrollbar-track
            background: var.$color__white

        &::-webkit-scrollbar-thumb
            background-color: var.$color__pale-orange
            border-radius: 20px
            border: 3px solid var.$color__white

        & > li:last-child
            border: unset

    .select-main__search > .list__main,
    .select-main__search > .list__main-django
        max-height: calc(4.5rem * 3 - #{var.$search-height})

    .list__main--item
        position: relative
        padding: unset

        & > input
            position: absolute
            inset: 0
            opacity: 0

        & > span
            display: block
            padding: var.$padding__xxxs var.$padding__xxs
            transition: background-color var.$transition__main

        & > input:checked ~ span
            background-color: var.$color__light-orange

    .list__main-django

        label
            position: relative
            display: block
            padding: var.$padding__xxxs var.$padding__xxs
            transition: background-color var.$transition__main

            & > input
                position: absolute
                inset: 0
                opacity: 0

                &::before
                    content: ""
                    display: block
                    height: 1rem
                    width: 1rem
                    background-color: #4ac1f7

            &:checked
                background-color: var.$color__light-orange
