.mobile-view
    display: none

.body-lock
    height: 100vh
    overflow: hidden

.overflow-visible
    overflow: visible

.invisible
    visibility: hidden

.display-none
    display: none

.padding-top-0
    padding-top: 0
