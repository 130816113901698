@use "../../main/abstract/variables" as var
@use "../abstract/variables" as desktopVar
@use "../../main/abstract/mixins" as mixin

.header
    position: fixed
    inset: 0 0 auto 0
    box-sizing: border-box
    width: 100%
    z-index: 100

    &__main-list
        position: absolute
        top: 0
        box-sizing: border-box
        display: grid
        grid-template-columns: 1fr auto
        column-gap: var.$gap__s
        align-items: center
        width: 100%
        padding: var.$padding__xs var.$padding__s var.$padding__xs calc(#{var.$padding__l} - #{var.$padding__xs})
        transition: var.$transition__slow

        &.show-bg
            background-color: var.$color__white

    &__home

        & > a
            display: block

        &--logo
            height: 2.5rem
            width: auto

    &__sub-list
        display: grid
        grid-auto-flow: column
        height: 100%

        & > li
            @include mixin.flex(center, center, row)

        &--link

            &::after
                content: ""
                display: block
                position: absolute
                right: 0
                height: 100%
                width: 1px
                background-color: var.$color__grey

            & > a
                position: relative
                padding:
                    left: var.$padding__xs
                    right: var.$padding__xs

            &:first-child
                margin-left: unset

            &:last-child
                margin-right: unset

                &::after
                    display: none

        &--link-border > a
            padding:
                left: var.$padding__xxs
                right: var.$padding__xxs
            margin:
                left: var.$padding__xs
                right: var.$padding__xs
            border: var.$border__main var.$color__black

            &::before
                z-index: -1

.header-img
    position: relative
    box-sizing: border-box
    min-height: 25rem
    height: 40vh
    max-height: 35rem

    &::before
        content: ""
        position: absolute
        width: 100%
        height: calc(100% + #{var.$el-size__s})
        @include mixin.bgImg("../img/landing.webp", top center, cover, no-repeat)
        z-index: -1

    &__title
        @include mixin.flex(center, center, row)
        box-sizing: border-box
        height: 100%
        width: 100%
        padding: desktopVar.$header var.$padding__l var.$padding__l
