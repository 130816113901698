@use "../abstract/variables" as var
@use "../abstract/mixins" as mixin

.link__yellow
    position: relative
    @include mixin.flex(center, center, row)
    box-sizing: border-box
    height: var.$el-size__s
    font-weight: 400
    font-size: .9rem
    z-index: 1

    &::before
        position: absolute
        @include mixin.pin(0, auto, 0, 0)
        content: ""
        display: block
        height: 100%
        width: 0
        background-color: rgba(var.$color__orange, .25)
        transition: var.$transition__main
        z-index: 0

    &:hover::before,
    &.active::before
        width: 100%

    &:active::before
        box-shadow: var.$shadow__small-inset

.link__simple,
.link__simple-underlined
    transition: color var.$transition__main

    &:hover
        color: var.$color__orange

    &:active
        color: var.$color__grey

.link__simple-underlined
    text-decoration: underline
