@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.reset-password
    @include mixin.centerX
    width: 100%
    max-width: var.$el-size__queen
    padding:
        left: var.$padding__m
        right: var.$padding__m

    & > h1
        padding:
            left: var.$padding__m
            right: var.$padding__m
        margin-bottom: var.$gap__xl

    &__form
        display: grid
        grid-auto-flow: row
        row-gap: var.$gap__xxxl

        &--inputs
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__l
            padding: var.$padding__m
            border: var.$border__main var.$color__orange
            background-color: var.$color__white

        &--submit
            padding:
                left: var.$padding__m
                right: var.$padding__m

            & > button
                width: 100%
