@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.home
    &__filter
        padding:
            left: var.$padding__l
            right: var.$padding__l

        & > form
            display: grid
            grid-template-columns: 9fr 1fr 2fr
            box-shadow: var.$shadow__main

        &--list
            grid-column: 1 / 2
            grid-row: 1 / 2
            display: grid
            grid-template-columns: repeat(3, 1fr)
            height: var.$el-size__xl

            & > li
                position: relative
                grid-row: 1 / 2
                z-index: 5

            & > .hover

                & > .home__filter--button-grid
                    background-color: var.$color__black
                    color: var.$color__white

                & > .home__filter--sublist
                    max-height: calc(#{var.$el-size__xl} * 3)

                & > .home__filter-inactive:first-child
                    background-color: var.$color__white
                    color: var.$color__black

                & > .home__filter-inactive:last-child
                    max-height: 0

        &--list-button
            box-sizing: border-box
            height: var.$el-size__xl
            width: 100%

        &--button-grid,
        &--button-lens
            background-color: var.$color__white
            transition: var.$transition__main

            &:hover
                background-color: var.$color__black
                color: var.$color__white

            &:active
                box-shadow: var.$shadow__main-hidden, var.$shadow__main-inset-dark
                background-color: var.$color__dark-grey

        &--button-grid
            position: relative
            @include mixin.flex(center, center, row)
            background-color: var.$color__white
            border-right: var.$border__main var.$color__medium-grey
            z-index: 2

            & > span
                display: grid
                grid-template-columns: repeat(2, auto)
                column-gap: var.$gap__s
                align-items: center
                width: max-content

                .icon__property,
                .icon__province
                    font-size: 1.15rem

                .icon__location,
                .icon__other
                    font-size: 1.25rem

        &--button-lens
            position: relative
            height: 100%
            width: 100%
            font-size: 1.15rem

            & > button
                position: absolute
                @include mixin.pin(0, 0, 0, 0)
                background-color: red

        &--search-field
            position: relative
            grid-column: 1 / 3
            grid-row: 1 / 2
            width: 10%
            height: var.$el-size__xl
            background-color: var.$color__white
            margin-left: auto
            overflow: hidden
            transition: width var.$transition__slow
            z-index: 5

            &.hover
                width: 100%

                .home__filter--button-lens
                    width: 10%

                .home__filter--search-input
                    width: 90%

        &--search-field-content
            @include mixin.flex(flex-start, center, row)
            height: 100%

            & > button,
            & > label
                @include mixin.flex(center, center, row)

        &--search-input
            height: inherit
            width: 0
            @include mixin.flex(center, center, row)
            overflow: hidden
            transition: width var.$transition__slow

            & > input
                height: max-content
                width: 100%
                padding:
                    left: var.$padding__xxxs
                    right: var.$padding__xxxs

        &--button-search
            grid-column: 3 / 4
            grid-row: 1 / 2
            background-color: var.$color__pale-orange
            transition: var.$transition__main

            &:hover
                background-color: var.$color__orange

            &:active
                box-shadow: var.$shadow__main-inset

            & > button
                text-transform: uppercase

        &--sublist
            display: grid
            grid-template-columns: 1fr
            grid-template-rows: auto 1fr
            width: 100%
            height: max-content
            max-height: 0
            box-sizing: border-box
            background-color: var.$color__white
            box-shadow: var.$shadow__main
            overflow: hidden
            transition: max-height var.$transition__main

            & > ul
                overflow: auto
                box-sizing: border-box
                height: max-content
                max-height: calc(#{var.$el-size__xl} * 3)
                padding:
                    left: var.$padding__xs
                    right: var.$padding__xs
                    bottom: var.$padding__xxxs

                /* Change scroll bar appearance */
                /* Works on Firefox */
                scrollbar-width: thin
                scrollbar-color: var.$color__pale-orange var.$color__white

                /* Works on Chrome, Edge, and Safari */
                &::-webkit-scrollbar
                    width: 12px

                &::-webkit-scrollbar-track
                    background: var.$color__white

                &::-webkit-scrollbar-thumb
                    background-color: var.$color__pale-orange
                    border-radius: 20px
                    border: 3px solid var.$color__white

                &:first-child
                    padding-top: var.$padding__xxs

                & > li:last-child  > label
                    border-bottom: unset

        .select-main__search > ul
            max-height: calc(#{var.$el-size__xl} * 3 - #{var.$search-height})

        &--sublist-filter
            position: sticky
            top: 0
            @include mixin.flex(center, center, row)
            height: max-content
            padding: calc(#{var.$padding__xxs} * 2) var.$padding__xs
            background-color: var.$color__white
            z-index: 2

            & > input
                box-sizing: border-box
                width: calc(100% - #{var.$padding__xs} * 2)
                padding:
                    left: calc(#{var.$padding__xs} - #{var.$padding__xxs})
                outline: var.$border__main var.$color__grey
                outline-offset: var.$padding__xxs
                transition: outline-color var.$transition__main

                &:focus
                    outline-color: var.$color__orange

        &--sublist-item
            position: relative
            display: block
            border-bottom: var.$border__main var.$color__medium-grey

            &::before,
            & > input
                position: absolute
                @include mixin.pin(0, 0, 0, 0)
                height: 100%

            &::before
                content: ""
                display: block
                width: 0
                background-color: var.$color__light-orange
                transition: var.$transition__main
                z-index: 0

            &:hover::before
                width: 100%

            &:active::before
                box-shadow: var.$shadow__small-inset

            & > input
                width: 100%
                opacity: 0

            & > span
                position: relative
                display: block
                padding: var.$padding__xxs var.$padding__xs
                transition: var.$transition__main
                z-index: 1

            & > input:checked ~ span
                background-color: var.$color__light-orange

    &__filter-inactive

        &:hover
            background-color: var.$color__white
            color: var.$color__black

        &:active
            box-shadow: unset
            color: var.$color__black

        & > span
            opacity: .25

    &__advice
        $pagination: .6rem
        padding:
            top: var.$padding__l
            left: var.$padding__l
            right: var.$padding__l
        overflow: hidden

        &--item
            box-sizing: border-box
            height: 100%
            border: var.$border__thick var.$color__grey
            padding: var.$padding__s
            background-color: var.$color__white

        &--downloads
            margin-top: var.$padding__s

        &--pagination
            @include mixin.centerX
            @include mixin.flex(center, cernter, row)
            flex-wrap: wrap
            margin-top: var.$padding__s

            & > span
                display: block
                height: $pagination
                width: $pagination
                border-radius: 50%
                background-color: var.$color__grey
                margin: var.$gap__xxs !important
                opacity: 1
                transition: background-color var.$transition__main

                &:hover
                    background-color: var.$color__pale-orange

            .swiper-pagination-bullet-active
                background-color: var.$color__dark-grey

                &:hover
                    background-color: var.$color__dark-grey

        .swiper-horizontal > .swiper-pagination-bullets
            width: max-content
            max-width: 100%

        .swiper-cube .swiper-slide
            height: auto

    &__properties
        margin:
            top: var.$padding__xl
            bottom: var.$padding__xxl

        &--header
            margin-bottom: var.$padding__xl
            padding:
                left: var.$padding__l
                right: var.$padding__l
            text-align: center

        &--header-buttons
            display: grid
            grid-template-columns: repeat(2, 1fr)
            column-gap: var.$gap__xl
            width: max-content
            @include mixin.centerX
            margin-top: var.$padding__m

            & > button
                position: relative
                @include mixin.flex(center, center, row)
                width: max-content
                padding-bottom: var.$gap__xxs
                transition: color var.$transition__main

                &:first-child
                    margin-left: auto

                &:last-child
                    margin-right: auto

                &::after
                    position: absolute
                    bottom: 0
                    content: ""
                    display: block
                    height: 3px
                    width: 0
                    background-color: var.$color__orange
                    transition: width var.$transition__main

                &:hover::after,
                &:disabled::after
                    width: 103%

                &:active
                    color: var.$color__orange

                &:disabled
                    cursor: not-allowed
                    color: var.$color__black

        &--header-buttons.no-current-auctions
            grid-template-columns: 1fr

    &__direct-sells,
    &__history
        display: grid
        margin:
            top: var.$padding__xxl
            bottom: var.$padding__xxl
        padding:
            left: var.$padding__l
            right: var.$padding__l

        &::before
            content: ""
            display: block
            grid-row: 1 / 2
            background-color: var.$color__black

        &--header
            grid-row: 1 / 2
            display: grid
            grid-template-rows: auto 1fr
            row-gap: var.$padding__l
            padding: var.$padding__l

            & > h2,
            & > a
                grid-column: 1 / 2

            & > h2
                color: var.$color__white

            & > a
                width: calc(#{var.$el-size__xxl} * 2)
                max-width: 100%
                margin-top: auto

        &--figure
            grid-row: 1 / 2
            padding:
                top: var.$padding__l
                bottom: var.$padding__l

            & > figure
                display: flex
                height: 100%
                width: 100%

                & > img
                    height: inherit
                    width: inherit
                    object-fit: cover
                    object-position: center

    &__direct-sells
        grid-template-columns: 4fr 3fr 1fr

        &::before
            grid-column: 1 / 3

        &--header
            grid-column: 1 / 2

        &--figure
            grid-column: 2 / 4

    &__history
        grid-template-columns: 1fr 3fr 4fr

        &::before
            grid-column: 2 / 4

        &--header
            grid-column: 3 / 4

        &--figure
            grid-column: 1 / 3

    &__how-we-work
        padding:
            left: var.$padding__l
            right: var.$padding__l

        & > h2
            text-align: center
            margin-bottom: var.$padding__m

        & > p
            margin-bottom: var.$gap__s
