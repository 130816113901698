@use "../abstract/variables" as var
@use "../abstract/mixins" as mixin

input,
textarea
    font-family: "text", sans-serif

    &::placeholder
        color: var.$color__grey
        opacity: 1
        transition: opacity var.$transition__main

    &:focus::placeholder
        opacity: 0

.input-label__main,
.input-label__icon,
.input-label__narrow,
.input-label__main--input-icon,
.input-label__file
    position: relative
    display: grid
    grid-auto-flow: row
    row-gap: var.$gap__xs

    &--label
        display: grid
        grid-template-columns: minmax(min-content, 1fr) auto
        column-gap: var.$gap__s
        align-items: self-end
        padding:
            left: var.$padding__xxs
            right: var.$padding__xxs

    & > input,
    & > textarea
        box-sizing: border-box
        outline: var.$border__main var.$color__grey
        @include mixin.centerX
        transition: outline-color var.$transition__main

        &:focus
            outline-color: var.$color__orange

    & > span > i
        transition: var.$transition__main

    .input__error
        outline-color: var.$color__red

    .errorlist
        text-align: right

.input-label__main,
.input-label__icon,
.input-label__main--input-icon

    & > input,
    & > textarea
        width: calc(100% - #{var.$padding__xxs} * 2)
        outline-offset: var.$padding__xxs
        margin:
            top: var.$padding__xxs
            bottom: var.$padding__xxs

.input-label__icon
    grid-template-columns: 1fr var.$el-size__s

    & > span
        grid-column: 1 / 3
        grid-row: 1 / 2

    & > input
        grid-column: 1 / 3
        grid-row: 2 / 3
        padding-right: var.$el-size__s

    & > button
        position: relative
        @include mixin.flex(flex-start, center, row)
        grid-column: 2 / 3
        grid-row: 2 / 3
        font-size: 1.3rem
        transition: color var.$transition__main

        & > i
            position: absolute
            transition: opacity var.$transition__main

        &:hover
            color: var.$color__orange

        &:active
            color: var.$color__grey

        &:invalid
            outline-color: red

.input-label__narrow > input
    width: calc(100% - #{var.$padding__xxs})
    outline-offset: calc(#{var.$padding__xxs} / 2)
    margin:
        top: calc(#{var.$padding__xxs} / 2)
        bottom: calc(#{var.$padding__xxs} / 2)
    padding:
        left: calc(#{var.$padding__xxs} / 2)
        right: calc(#{var.$padding__xxs} / 2)

.input-label__checkbox
    position: relative
    display: grid
    grid-template-columns: auto 1fr
    column-gap: var.$gap__xs
    align-items: flex-start

    & > input
        position: absolute
        @include mixin.pin(0, 0, 0, 0)
        height: 100%
        width: 100%
        z-index: 2

    &--icon
        position: relative
        @include mixin.flex(center, center, row)
        font-size: 1rem
        transition: color var.$transition__main

        & > i
            transition: opacity var.$transition__main

        & > .icon__box-checked
            position: absolute
            opacity: 0

    & > input:hover ~ &--icon
        color: var.$color__orange

    & > input:active ~ &--icon
        color: var.$color__grey

    & > input:checked ~ &--icon

        & > .icon__box
            opacity: 0

        & > .icon__box-checked
            opacity: 1

    & > p
        position: relative
        z-index: 3

.input__password
    padding-right: var.$padding__s

.button__password
    position: absolute
    @include mixin.pin(auto, 0, 0, auto)
    height: var.$button-password
    width: calc(#{var.$padding__s} + #{var.$padding__xxs})
    @include mixin.flex(center, center, row)
    font-size: 1.25rem
    transition: color var.$transition__main

    &:hover
        color: var.$color__orange

    &:active
        color: var.$color__grey

    & > i
        position: absolute
        transition: opacity var.$transition__main

.input__error .icon__box
    color: var.$color__red

.errorlist
    height: auto
    color: var.$color__red
    font-weight: 400
    font-size: .75rem
    line-height: 1.25

.input-label__main--input-icon
    position: relative

    & > input
        padding-right: var.$padding__s

    & > i
        position: absolute
        top: 0
        right: var.$padding__xxs
        @include mixin.flex(center, center, row)
        width: var.$padding__xs
        height: 100%

.input-error__wrapper
    text-align: right
    overflow: hidden
    transition: height var.$transition__main

.input-label__error > input
    outline-color: var.$color__red

.input-label__file

    & > input
        display: none

    &--list
        display: grid
        padding-top: var.$padding__xxs
        border-top: var.$border__main var.$color__grey

        &::after
            content: ""
            display: block
            width: 100%
            height: 0
            border-top: var.$border__main transparent
            transition: var.$transition__main

        & > li
            display: flex
            padding:
                left: var.$padding__xxs
                right: var.$padding__xxs
            transition: height var.$transition__main

    &--list-item
        display: grid
        grid-template-columns: auto 1fr auto
        column-gap: var.$gap__m
        align-items: center
        width: 100%
        padding-bottom: var.$padding__xxs
        word-break: break-all

        & > i
            font-size: 1.25rem

    &--input
        position: relative
        display: flex
        justify-content: center
        align-items: center
        width: 50%
        min-width: max-content

        & > input
            position: absolute
            inset: 0
            opacity: 0

.file-list-not-empty::after
    height: var.$padding__xxs
    border-top-color: var.$color__grey
