@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.not-found
    display: grid
    grid-template-columns: auto 1fr
    column-gap: var.$gap__xxl

    &__info
        padding: calc(#{var.$padding__l} * 2) var.$padding__l

        & > div
            margin-top: var.$padding__xl
            max-width: var.$el-size__xxxl

    &__img
        display: grid
        grid-template-columns: repeat(4, 1fr)
        grid-template-rows: var.$padding__l 1fr var.$padding__l

        &--photo
            position: relative
            display: block
            @include mixin.bgImg('../img/404.webp', bottom, cover, no-repeat)
            grid-column: 1 / 4
            grid-row: 2 / 3

        &--bg
            display: block
            height: 100%
            width: 100%
            grid-column: 3 / 5
            grid-row: 1 / 4
            background-color: var.$color__black
