@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.profile

    &__data
        display: grid
        grid-template-columns: max-content 1fr

    &__aside
        position: sticky
        top: 0
        display: grid
        height: max-content
        padding: var.$padding__l var.$padding__m

        button
            width: 100%
            display: grid
            grid-template-columns: 1.5rem 1fr
            column-gap: var.$gap__xs
            text-align: left

            & > i
                width: 100%

            & > .icon__my-data
                font-size: 1rem

            & > .icon__clients
                font-size: 1.15rem

        hr
            border-top: var.$border__main var.$color__grey
            margin:
                top: var.$gap__xs
                bottom: var.$gap__xs

    &__resume-wrapper
        overflow: hidden
        transition: height var.$transition__slow

    &__resume
        display: block
        width: var.$el-size__queen
        @include mixin.centerX

        & > h1
            margin-bottom: var.$gap__l
            padding:
                left: var.$padding__m
                right: var.$padding__m

        &--data
            display: grid
            grid-template-columns: repeat(2, 1fr)
            row-gap: var.$gap__m
            column-gap: var.$gap__m
            padding: var.$padding__m
            border: var.$border__main var.$color__grey

        &--address,
        &--email
            grid-column: 1 / 3

    &__clients
        position: relative
        padding:
            left: var.$padding__l
            right: var.$padding__l
        overflow: visible
        transition: height var.$transition__slow

        &--header
            display: grid
            grid-template-columns: 1fr auto
            column-gap: var.$gap__m
            padding:
                left: var.$padding__m
                bottom: var.$padding__xs

        &--add
            display: grid
            grid-template-columns: max-content 2rem
            background-color: var.$color__pale-orange
            padding:
                top: var.$padding__xxxs
                bottom: var.$padding__xxxs
            font-size: .75rem
            transition: var.$transition__main

            & > span
                position: relative
                padding:
                    left: var.$padding__xxs
                    right: var.$padding__xxs

                &::after
                    position: absolute
                    content: ""
                    display: block
                    @include mixin.pin(-#{var.$padding__xxxs}, auto, auto, 100%)
                    height: calc(100% + #{var.$padding__xxxs} * 2)
                    width: 1px
                    background-color: var.$color__white

            & > i
                height: 100%
                width: 100%
                font-size: .6rem
                transition: transform var.$transition__main

            &:hover
                background-color: var.$color__orange

            &:active
                box-shadow: var.$shadow__small-inset

        &--content
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__m
            padding-top: var.$padding__m
            border-top: var.$border__main var.$color__grey

            & > h1
                padding:
                    left: var.$gap__xl
                    right: var.$gap__xl

                &:last-of-type
                    padding-top: calc(#{var.$gap__xl} - #{var.$gap__m})

    &__add-new-client
        $option-height: 3rem
        position: absolute
        right: var.$padding__l
        width: var.$el-size__queen
        max-width: calc(100% - #{var.$padding__l} * 2)
        background-color: var.$color__white
        box-shadow: var.$shadow__main
        overflow: hidden
        transition: height var.$transition__main
        z-index: 100

        &--options
            padding: var.$padding__s

        &--option
            position: relative
            display: grid
            grid-template-columns: var.$el-size__s 1fr
            align-items: center
            text-align: center
            border-bottom: var.$border__main var.$color__light-grey

            &:first-child
                border-top: var.$border__main var.$color__light-grey

        &--option-button
            grid-column: 2 / 3
            grid-row: 1 / 2
            box-sizing: border-box
            width: 100%
            max-height: $option-height
            padding: var.$padding__xs var.$padding__xxs
            transition: var.$transition__main

            &::before
                position: absolute
                @include mixin.pin(0, 0, 0, 0)
                content: ""
                display: block
                width: 0
                height: 100%
                background-color: var.$color__light-orange
                transition: var.$transition__main
                z-index: -1

            &:hover::before
                width: 100%

            &:active::before
                box-shadow: var.$shadow__small-inset

        &--option-info
            grid-column: 1 / 2
            grid-row: 1 / 2
            transition: color var.$transition__main
            z-index: 2

            &::before
                position: absolute
                @include mixin.pin(0, auto, 0, 0)
                content: ""
                display: block
                background-color: var.$color__black
                width: 0
                height: 100%
                transition: width var.$transition__main
                z-index: -1

            &:hover
                color: var.$color__white

                &::before
                    width: 100%

        &--option-details
            position: relative
            grid-column: 1 / 3
            grid-row: 1 / 2
            display: grid
            align-items: center
            box-sizing: border-box
            text-align: left
            color: var.$color__white
            padding-right: var.$padding__xxs
            margin-left: calc(#{var.$el-size__s} + #{var.$gap__s})
            overflow: hidden
            transition: var.$transition__main
            z-index: 2

            & > p
                margin:
                    top: var.$padding__xxxs
                    bottom: var.$padding__xxxs

    &__client
        position: relative
        padding: var.$padding__m
        border: var.$border__main var.$color__orange

        &--header
            display: grid
            grid-template-columns: repeat(4, 1fr)
            column-gap: var.$gap__l
            padding-bottom: var.$padding__xs
            border-bottom: var.$border__main var.$color__grey

        &--content
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__m
            padding-top: var.$gap__l

        &--data
            display: grid
            grid-template-columns: repeat(4, 1fr)
            column-gap: var.$gap__l

        &--data-phone
            grid-column: 4 / 5

        &--delete
            $button-size: 1.5rem
            position: absolute
            @include mixin.pin(.5rem, .5rem, auto, auto)
            @include mixin.flex(center, center, column)
            box-sizing: border-box
            height: $button-size
            width: $button-size
            border: var.$border__main var.$color__grey
            border-radius: 50%
            transition: border-color var.$transition__main

            &::before,
            &::after
                content: ""
                display: block
                position: absolute
                height: 1px
                width: .75rem
                background-color: var.$color__grey
                transform-origin: center
                transition: background-color var.$transition__main

            &::before
                transform: rotate(45deg)

            &::after
                transform: rotate(-45deg)

            &:hover
                border-color: var.$color__red

                &::before,
                &::after
                    background-color: var.$color__red

    &__add-client-form
        @include mixin.darkTransparentBg
        padding: calc(#{var.$padding__l} + #{var.$padding__m} * 2)

        &--container
            position: relative
            box-sizing: border-box
            background-color: var.$color__white
            width: 100%
            @include mixin.centerX
            @include mixin.centerY
            padding: var.$padding__l
            z-index: 2

            & > h1
                margin-bottom: var.$padding__s
                padding:
                    left: var.$padding__m
                    right: var.$padding__m

        &--transition
            transition: height var.$transition__main

            &:last-child
                overflow: hidden

        &--fields-simple,
        &--fields-multi-wrapper
            padding: var.$padding__m
            border: var.$border__main var.$color__orange

        &--fields-simple
            display: grid
            grid-template-columns: repeat(2, 1fr)
            grid-gap: var.$gap__l

            & > .profile-company:first-of-type
                grid-column: 1 / 3

        &--fields-header
            display: block
            box-sizing: border-box
            grid-column: 1 / 3
            padding:
                left: var.$padding__xxs
                right: var.$padding__xxs
                bottom: var.$padding__xs
            margin-bottom: var.$padding__xs
            border-bottom: var.$border__main var.$color__grey

            & > h2
                margin-bottom: var.$gap__xxs

        &--fields-multi-header
            display: grid
            grid-template-columns: 1fr auto
            grid-gap: var.$gap__l
            margin-bottom: var.$gap__l

            &--name > h3
                margin-bottom: var.$gap__xxs

            &--bidder-num
                display: grid
                grid-template-columns: 1fr var.$el-size__xl
                column-gap: var.$gap__s
                align-items: center

                & > p
                    text-align: right

                .list__main
                    padding:
                        left: var.$padding__xxxs
                        right: var.$padding__xxxs

            & > nav
                box-sizing: border-box
                grid-column: 1 / 3
                width: 100%
                border-bottom: var.$border__main var.$color__grey
                padding:
                    left: var.$padding__m
                    right: var.$padding__m

                .swiper-slide
                    width: max-content

        &--button
            position: relative
            padding: var.$padding__xxs
            transition: color var.$transition__main

            &::after
                content: ""
                display: block
                position: absolute
                bottom: 0
                left: 0
                height: 3px
                width: 0
                background-color: var.$color__orange
                transition: width var.$transition__main

            &:hover
                color: var.$color__orange

                &::after
                    width: 100%

        &--active-button
            color: var.$color__orange

            &::after
                width: 100%

        &--swiper-next,
        &--swiper-prev
            position: absolute
            top: 0
            @include mixin.flex(center, center, row)
            height: 100%
            width: var.$padding__m
            font-size: .5rem
            background-color: var.$color__white
            z-index: 1
            transition: var.$transition__main

            &:hover
                color: var.$color__orange

            & > i
                transform-origin: center

        &--swiper-next
            right: 0

            & > i
                transform: rotate(-90deg)

        &--swiper-prev
            left: 0

            & > i
                transform: rotate(90deg)

        &--swiper-next.swiper-button-disabled,
        &--swiper-prev.swiper-button-disabled
            opacity: 0

        &--terms-wrapper
            position: relative
            display: grid
            grid-template-columns: auto 1fr
            column-gap: var.$gap__m
            align-items: start

            & > input
                position: absolute
                @include mixin.pin(0, 0, 0, 0)
                height: 100%
                width: 100%
                z-index: 3

                &:checked ~ span

                    .icon__box
                        opacity: 0

                    .icon__box-checked
                        opacity: 1

            &:hover > span
                color: var.$color__orange

            .input-error__wrapper
                grid-column: 1 / 3
                text-align: left

                & > span
                    display: inline-block
                    padding-top: var.$gap__xs

        // TODO clear when django forms will be finished
        &--fields-wrapper
            padding: var.$padding__m
            border: var.$border__main var.$color__orange

        &--fields,
        &--fields-multi
            display: grid
            grid-gap: var.$gap__l

        &--fields
            grid-template-columns: repeat(2, 1fr)

            .input-label__main:last-of-type

                & > span
                    display: block

        &--field-name
            grid-column: 1 / 3

        &--fields-multi-transition
            display: block
            box-sizing: border-box
            overflow: hidden
            transition: height .3s ease-in-out

        &--fields-multi
            grid-template-columns: repeat(2, 1fr)
            align-items: end
            padding: 1px

            & > input
                display: none
                position: absolute

        &--field-multi-person-name
            grid-column: 1 / 3

        &--field-multi-company-name,
        &--field-multi-surname
            grid-column: 1 / 2

        &--field-multi-percent
            grid-column: 2 / 3

        &--field-multi-id
            grid-column: 1 / 2

        &--field-multi-phone
            grid-column: 2 / 3

        &--terms
            margin:
                top: var.$padding__s
                bottom: var.$padding__xl

            & > div
                padding:
                    left: var.$padding__m
                    right: var.$padding__m

        &--submit-wrapper
            display: grid
            grid-template-columns: repeat(2, 1fr)
            column-gap: var.$gap__l

            & > button:first-child
                margin-right: unset

            &  > button:last-child
                margin-left: unset

        &--submit,
        &--next-multi
            width: 100%
            max-width: var.$el-size__xxxl
            @include mixin.centerX

        &--next-multi
            margin-top: var.$padding__m

        // TODO end

        &--close
            $button-size: 2rem
            position: absolute
            @include mixin.pin(0, 0, auto, 0)
            @include mixin.flex(flex-end, center, row)
            box-sizing: border-box
            width: 100%
            padding: var.$padding__xxs

            & > span
                position: relative
                @include mixin.flex(center, center, row)
                height: $button-size
                width: $button-size
                border: var.$border__main var.$color__black
                border-radius: 50%
                transition: border-color var.$transition__main

                &::before,
                &::after
                    position: absolute
                    content: ""
                    display: block
                    width: 60%
                    height: 1px
                    background-color: var.$color__black
                    transform-origin: center
                    transition: background-color var.$transition__main

                &::before
                    transform: rotate(45deg)

                &::after
                    transform: rotate(-45deg)

            &:hover > span
                border-color: var.$color__orange

                &::before,
                &::after
                    background-color: var.$color__orange

            &:active > span
                border-color: var.$color__grey

                &::before,
                &::after
                    background-color: var.$color__grey

        &--close-bg
            @include mixin.closeBg

        &--main-error
            text-align: center
            overflow: hidden
            transition: height var.$transition__main

            & > p
                margin-bottom: var.$gap__xl

    .list__main-wrapper
        top: unset

    .select-main__content-wrapper
        height: auto

        & > .input-label__main
            z-index: 2

            & > span:last-child
                height: var.$button-size__s
                bottom: 0
                transition: transform var.$transition__main

        &.hover > div
            max-height: calc(4.5rem * 2)

    &__auctions
        overflow: hidden
        transition: height var.$transition__slow

        &--header
            margin:
                top: calc(#{var.$padding__l} * 2)
                bottom: var.$padding__l
            text-align: center
