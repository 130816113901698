@use "../../main/abstract/variables" as var
@use "../abstract/variables" as desktopVar

.container__main
    max-width: desktopVar.$breakpoint__l
    margin:
        left: auto
        right: auto

.container__narrow
    max-width: desktopVar.$breakpoint__m
    margin:
        left: auto
        right: auto

.d-top-margin__main
    margin-top: calc(#{var.$padding__l} + #{desktopVar.$header})
