@use "../../main/abstract/variables" as var

.direct-sells

    &__nav
        position: relative
        top: -#{var.$el-size__s}
        padding:
            left: var.$padding__l
            right: var.$padding__l

        &--list
            display: grid
            grid-template-columns: repeat(6, 1fr)
            column-gap: var.$gap__s
            padding:
                left: var.$padding__s
                right: var.$padding__s
            background-color: var.$color__white

        &--list-item
            display: grid
            grid-template-rows: var.$el-size__m 1fr
            row-gap: var.$gap__m
            padding:
                top: var.$padding__m
                bottom: var.$padding__m
            text-align: center
            transition: color var.$transition__main

            &:hover
                color: var.$color__orange

            & > i
                font-size: 2.5rem

            & > .icon__c-motoring
                font-size: 3.25rem

    &__swiper-item

        & > a
            display: grid
            grid-template-rows: repeat(2, auto) 1fr

            & > figure
                margin-bottom: var.$gap__m

            & > h3
                margin-bottom: var.$gap__xl

            & > p
                margin-top: auto

    &__info
        box-shadow: var.$shadow__main
        margin:
            top: var.$padding__xl
            bottom: var.$el-size__xxl
        padding: var.$padding__l
        overflow: visible

        & > h2
            margin-bottom: var.$gap__xl
            text-align: center
