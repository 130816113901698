@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.contact
    padding:
        left: var.$padding__l
        right: var.$padding__l

    &__form
        display: grid
        grid-template-columns: 3fr 2fr
        margin-top: var.$padding__m
        gap: var.$gap__xl

        &--details
            display: grid
            grid-template-columns: repeat(2, 1fr)
            gap: var.$gap__l

            & > div:last-child
                grid-column: 1 / 3

            textarea
                height: var.$el-size__xxxl

        &--files
            display: grid
            grid-template-rows: 1fr auto
            row-gap: var.$gap__m

            & > .input-label__file
                height: max-content

        &--main-error
            transition: height var.$transition__main

            & > p
                margin-top: var.$gap__m

        &--submit
            grid-column: 2 / 3
            grid-row: 2 / 3

    &__submit-result
        @include mixin.darkTransparentBg
        padding: calc(#{var.$padding__l} + #{var.$padding__m} * 2)

        &--content
            position: relative
            box-sizing: border-box
            background-color: var.$color__white
            width: 100%
            @include mixin.centerX
            @include mixin.centerY
            padding: var.$padding__l
            z-index: 2

        &--content-wrapper
            display: grid
            row-gap: var.$gap__l
            transition: height var.$transition__main

            & > button
                width: 100%
                max-width: var.$el-size__xxl
                margin-left: auto

        &--close-bg
            @include mixin.closeBg
