@use "../../main/abstract/variables" as var

.sign-up
    padding:
        left: var.$padding__l
        right: var.$padding__l

    & > h1
        padding:
            left: var.$padding__m
            right: var.$padding__m
        margin-bottom: var.$gap__xl

    & > form
        display: grid
        grid-template-columns: repeat(3, 1fr)
        column-gap: var.$gap__s
        row-gap: var.$gap__xxxl

    &__form
        display: grid
        grid-auto-flow: row
        row-gap: var.$gap__xl
        box-sizing: border-box
        height: max-content
        overflow: hidden
        transition: height var.$transition__main

        &--inputs
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__l
            padding: var.$padding__m var.$padding__s
            border: var.$border__main var.$color__orange
            background-color: var.$color__white

        &--enable
            text-align: right
            font-size: .75rem
            transition: var.$transition__main

            &:hover
                color: var.$color__orange

        &--submit
            grid-column: 2 / 3
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__l

            & > p
                text-align: center

                & > a
                    text-decoration: underline
                    transition: color var.$transition__main

                    &:hover
                        color: var.$color__orange

                    &:active
                        color: var.$color__grey

        &--submit-wrapper
            display: block

            & > button
                width: 100%

        &--terms
            padding:
                left: var.$padding__s
                right: var.$padding__s

            .errorlist
                margin-top: var.$gap__xs

.step-one
    box-sizing: border-box
    border: var.$border__main var.$color__orange

    .sign-up__form--inputs
        border-color: transparent
