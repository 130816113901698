@use "../../main/abstract/variables" as var
@use "../abstract/variables" as desktopVar

.search-results
    display: grid
    grid-template-columns: auto 1fr
    column-gap: var.$gap__xl
    margin-top: var.$padding__xl
    padding:
        left: var.$padding__l
        right: var.$padding__l

    &__aside
        position: sticky
        top: var.$padding__m
        height: max-content
        width: max-content
        min-width: var.$el-size__xxxl
        max-width: 20rem

    &__form
        display: grid
        grid-template-columns: 1fr
        row-gap: var.$gap__l

        &--search
            position: relative
            display: grid
            grid-template-rows: var.$el-size__xxs auto

            .input-label__main
                grid-row: 2 / 3
                background-color: var.$color__white
                margin: var.$padding__xxxs

                &::before
                    content: ""
                    display: block
                    position: absolute
                    inset: -#{var.$padding__xxxs}
                    height: calc(100% + #{var.$padding__xxxs} * 2)
                    width: calc(100% + #{var.$padding__xxxs} * 2)
                    background-color: var.$color__light-grey
                    z-index: -1

                & > input
                    padding-left: calc(#{var.$el-size__s} - #{var.$padding__xxs} / 2)

        &--search-button
            position: absolute
            height: 100%
            width: var.$el-size__s
            transition: color var.$transition__main

            &:hover
                color: var.$color__orange

            &:active
                color: var.$color__grey

        &--selects
            display: grid
            grid-template-columns: 1fr
            row-gap: var.$gap__l
            padding: var.$padding__s var.$padding__m
            background-color: var.$color__light-grey

        &--prices
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__s

            & > .input-label__narrow
                background-color: var.$color__white

    .button__solid-blue
        height: var.$el-size__l

    &__inactive

        & > header
            opacity: .25

        & > div > .input-label__main
            background-color: transparent

            & > input
                background-color: transparent

                &:focus
                    outline-color: var.$color__grey

            & > span
                opacity: .25

        & > .hover

            & > .input-label__main

                & > input,
                & > input:focus
                    outline-color: var.$color__grey

                & > span > i
                    color: var.$color__black
                    transform: scaleY(1)

            & > .list__main-wrapper
                max-height: 0

    &__results
        display: grid
        grid-auto-flow: row
        grid-auto-rows: max-content
        row-gap: var.$gap__l

        &--filter
            display: grid
            grid-template-rows: var.$el-size__xxs auto
            column-gap: var.$gap__m

        &--filter-links
            display: grid
            grid-template-columns: repeat(3, 1fr)
            column-gap: var.$gap__m

        &--filter-link
            box-sizing: border-box
            display: grid
            grid-template-columns: auto 1fr
            column-gap: var.$gap__xs
            align-items: center
            height: 2.75rem
            margin:
                top: var.$padding__xxxs
                bottom: var.$padding__xxxs
            padding:
                left: var.$padding__xxs
                right: var.$padding__xxs
            border: var.$border__main var.$color__grey
            transition: var.$transition__main

            & > i
                font-size: 1rem
                grid-column: 1 / 2
                grid-row: 1 / 2

            & > span
                display: flex
                align-items: center

            &:hover
                background-color: var.$color__black
                border-color: var.$color__black
                color: var.$color__white

                & > .text-grey-light
                    color: var.$color__dark-grey

            &:active
                background-color: var.$color__dark-grey
                border-color: var.$color__dark-grey
                color: var.$color__white
                box-shadow: var.$shadow__main-inset-dark

        &--item
            display: grid
            grid-template-columns: 2fr 3fr
            height: max-content
            transition: var.$transition__main

            & > section
                transition: var.$transition__main

            &:hover
                background-color: var.$color__black

                & > section
                    border-color: var.$color__black
                    color: var.$color__white

            &:active
                background-color: var.$color__dark-grey
                color: var.$color__white

                & > section
                    box-shadow: var.$shadow__main-inset-dark
                    border-color: var.$color__dark-grey
                    color: var.$color__white

        &--item-img
            background-position: center
            background-size: cover
            background-repeat: no-repeat

        &--item-info
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__m
            height: max-content
            border:
                top: var.$border__main var.$color__grey
                bottom: var.$border__main var.$color__grey
                right: var.$border__main var.$color__grey
            padding: var.$padding__m

        &--item-name
            margin-top: var.$gap__m

            & > p
                margin-top: var.$gap__s

        &--item-features > span
            margin-left: var.$gap__m

        &--item-numbers
            display: grid
            grid-template-columns: auto 1fr
            column-gap: var.$gap__m
            margin-top: var.$gap__m

        &--item-price
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__xxs

        &--direct-sells
            display: grid
            grid-template-columns: repeat(3, 1fr)
            gap: var.$gap__m

            @media screen and (max-width: desktopVar.$breakpoint__m)
                grid-template-columns: repeat(2, 1fr)

            &__filter-links
                display: grid
                grid-template-columns: repeat(2, 1fr)
                column-gap: var.$gap__m

            & > article
                display: grid

            &__item
                display: grid
                grid-template-rows: var.$el-size__xxl 1fr
                row-gap: var.$gap__s
                padding: var.$padding__xxs var.$padding__xxs var.$padding__xs
                box-shadow: var.$shadow__main, var.$shadow__main-inset-hidden
                transition: var.$transition__main

                & > section
                    transition: var.$transition__main

                &:hover
                    background-color: var.$color__light-orange

                &:active
                    box-shadow: var.$shadow__main-hidden, var.$shadow__main-inset
                    background-color: var.$color__pale-orange

            &__item-img
                background-position: center
                background-size: cover
                background-repeat: no-repeat

            &__item-info
                display: grid
                grid-template-rows: repeat(2, auto) 1fr
                row-gap: var.$gap__s

            &__item-name
                margin-bottom: var.$gap__m

                & > p
                    margin-top: var.$gap__xs

            .timer-simple
                margin-top: auto

        &--empty > p
            margin-top: var.$gap__m

    .paginator
        margin-top: var.$padding__m
