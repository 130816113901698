@use "../abstract/variables" as var

.title-xxl__light
    font-weight: 300
    font-size: 6.5rem
    line-height: 1

.title-xl__light
    font-weight: 300
    font-size: 4rem
    line-height: 1

    @media all and (max-width: var.$common-breakpoint__s)
        font-size: 3.5rem

.title-l
    font-weight: 400
    font-size: 3rem
    line-height: 1

.title-m
    font-weight: 400
    font-size: 2.25rem
    line-height: 1.25

.title-s
    font-weight: 400
    font-size: 1.5rem
    line-height: 1.25

.title-xs
    font-weight: 400
    font-size: 1.25rem
    line-height: 1.25

.title-xs__semi-bold
    font-weight: 600
    font-size: 1.25rem
    line-height: 1.25

.text-l
    font-weight: 400
    font-size: 1rem
    line-height: 1.25

.text-l__semi-bold
    font-weight: 600
    font-size: 1rem
    line-height: 1.25

.text-m
    font-weight: 400
    font-size: .9rem
    line-height: 1.25

.text-m__semi-bold
    font-weight: 600
    font-size: .9rem
    line-height: 1.25

.text-m__extra-bold
    font-weight: 800
    font-size: .9rem
    line-height: 1.25

.text-s
    font-weight: 400
    font-size: .75rem
    line-height: 1.25

.text-xs
    font-weight: 400
    font-size: .6rem
    line-height: 1.25

.text-semi-bold
    font-weight: 600

.text-bold
    font-weight: 800

.text-grey-light
    color: var.$color__light-grey
    transition: color var.$transition__main

.text-grey
    color: var.$color__medium-grey

.text-dark-grey
    color: var.$color__dark-grey

.text-yellow
    color: var.$color__orange

.text-white
    color: var.$color__white

.text-error
    color: var.$color__red
    font-weight: 400
    font-size: .75rem
    line-height: 1.25

.text-red
    color: var.$color__red

.text-italic
    font-style: italic

.text-light
    font-weight: 300

.text-uppercase
    text-transform: uppercase

.text-word-break-all
    word-break: break-all

.text-link
    color: var.$color__orange
    text-decoration: underline
    transition: color var.$transition__main

    &:hover
        color: var.$color__medium-grey

.line-height-l
    line-height: 1.5
