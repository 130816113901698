@use "../abstract/variables" as var
@use "../abstract/mixins" as mixin

.list__main-wrapper
    position: absolute
    box-shadow: var.$shadow__main
    overflow: hidden
    transition: var.$transition__main

.list__main,
.list__main-django
    box-sizing: border-box
    display: grid
    grid-auto-flow: row
    padding: var.$padding__xxxs var.$padding__xs

    & > li
        display: flex
        border-bottom: var.$border__main var.$color__grey

    &--item,
    label
        position: relative
        box-sizing: border-box
        text-align: left
        width: 100%
        padding: var.$padding__xxxs var.$padding__xxs
        z-index: 1

        &::before
            content: ""
            display: block
            position: absolute
            @include mixin.pin(0, auto, 0, 0)
            height: 100%
            width: 0
            background-color: var.$color__light-orange
            transition: var.$transition__main
            z-index: -1

        &:hover::before
            width: 100%

        &:active::before
            box-shadow: var.$shadow__main-inset

    .list__main--item-checked

        &:hover::before
            width: 100%

        &:active::before
            box-shadow: unset
