@use "../../main/abstract/variables" as var

.verify,
.verify__form
    display: grid
    grid-auto-flow: row
    row-gap: var.$gap__l

.verify
    padding:
        left: var.$padding__l
        right: var.$padding__l

    &__form
        width: 100%

        input[type="number"]
            -moz-appearance: textfield

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button
            -webkit-appearance: none

        &--inputs
            display: grid
            grid-template-columns: repeat(6, auto)
            column-gap: var.$gap__xs
            width: max-content
            margin:
                top: var.$gap__l
                bottom: var.$gap__l

        &--input
            width: var.$el-size__l
            border-bottom: var.$border__main var.$color__medium-grey
            font-weight: 400
            font-size: 3rem
            line-height: 1.25
            text-align: center

        &--submit
            max-width: var.$el-size__xxxl

    &__email-form
        display: grid
        grid-auto-flow: row
        row-gap: var.$gap__xl
        width: 100%
        max-width: calc(#{var.$el-size__queen} - #{var.$padding__m} *2)

    &__link
        margin-top: var.$gap__xl
