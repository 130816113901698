@use "./variables" as var
@use "./mixins" as mixin

@keyframes bounceUp
    0%
        transform: translateY(0)
        opacity: 1
    25%
        transform: translateY(#{var.$padding__m})
    100%
        transform: translateY(-7rem)
        opacity: 0

@keyframes bounceFromDown
    0%
        transform: translateY(7rem)
        opacity: 0
    75%
        transform: translateY(-#{var.$padding__m})
    100%
        transform: translateY(0)
        opacity: 1

@keyframes bounceToDown
    0%
        transform: translateY(0)
        opacity: 1
    25%
        transform: translateY(-#{var.$padding__m})
    100%
        transform: translateY(7rem)
        opacity: 0

@keyframes slideDown
    0%
        transform: translateY(-7rem)
        opacity: 0
    100%
        transform: translateY(0)
        opacity: 1

.bounce-up
    @include mixin.animation(bounceUp, .3s, forwards)

@keyframes translate-left
    from
        transform: translateX(0)
        opacity: 1
    to
        transform: translateX(50%)
        opacity: 0

@keyframes translate-down
    from
        transform: translateY(0)
        opacity: 1
    to
        transform: translateY(100%)
        opacity: 0

@keyframes opacityAni
    from
        opacity: 0
    to
        opacity: 1

/* Regular menu transition */
@keyframes closeMenuToLeft
    from
        transform: translateX(0)
    to
        transform: translateX(-100%)

@keyframes closeMenuToRight
    from
        transform: translateX(0)
    to
        transform: translateX(100%)

@keyframes openMenuFromLeft
    from
        transform: translateX(-100%)
    to
        transform: translateX(0)

@keyframes openMenuFromRight
    from
        transform: translateX(100%)
    to
        transform: translateX(0)

@keyframes slideDown
    from
        transform: translateY(-102%)
    to
        transform: translateY(0)

@keyframes slideUp
    from
        transform: translateY(0)
    to
        transform: translateY(-102%)
