@use "../../main/abstract/variables" as var
@use "../abstract/variables" as desktopVar
@use "../../main/abstract/mixins" as mixin

.auction
    margin-top: calc(#{desktopVar.$header} + #{var.$padding__m})
    &__details
        display: grid
        grid-template-columns: 2fr 4fr
        column-gap: var.$padding__l
        padding:
            left: var.$padding__l
            right: var.$padding__l
        overflow: hidden
        transition: height var.$transition__slow

        &--header
            margin:
                top: var.$padding__l
                bottom: var.$padding__l
            padding:
                top: var.$padding__m
                bottom: var.$padding__m

        &--data
            width: 100%
            margin-top: var.$padding__m

            & > li,
            .timer
                $icon-size: 2.25rem
                display: grid
                grid-template-columns: auto 1fr
                align-items: center
                column-gap: var.$gap__s
                padding:
                    top: var.$gap__s
                    bottom: var.$gap__s
                border-top: var.$border__main var.$color__grey

                &:first-child
                    border-top: unset

                & > i
                    height: $icon-size
                    width: $icon-size
                    border: var.$border__main var.$color__black
                    border-radius: 50%

                & > .icon__euro
                    font-size: .9rem

                & > .icon__location
                    font-size: 1.1rem

                & > .icon__ref
                    box-sizing: border-box
                    display: flex
                    align-items: center
                    justify-content: center
                    font-size: .8rem
                    font-weight: 600
                    font-style: normal
                    padding-top: .15rem

    &__main-content
        display: grid
        grid-template-columns: minmax(var.$el-size__xxxl, 1fr) 3fr
        column-gap: var.$gap__xxl
        margin:
            top: calc(#{var.$padding__m} + #{desktopVar.$header})
            bottom: var.$padding__xxl
        padding:
            left: var.$padding__l
            right: var.$padding__l

        h4
            margin-bottom: var.$gap__xxs

        &--header
            position: fixed
            inset: 0 0 auto
            background-color: var.$color__light-grey
            overflow: hidden
            transition: height var.$transition__main
            z-index: 100

        &--header-content
            display: grid
            grid-template-columns: desktopVar.$header 1fr auto
            align-items: center
            height: desktopVar.$header

        &--header-button,
        &--header-title,
        &--header-price
            box-sizing: border-box
            @include mixin.flex(center, flex-start, column)
            height: 100%
            padding:
                top: var.$padding__xs
                bottom: var.$padding__xs

        &--header-button
            font-size: 1rem
            padding:
                left: var.$padding__m
                right: var.$padding__m

        &--header-title
            padding:
                left: var.$padding__m
                right: var.$padding__m
            border:
                left: var.$border__main
                right: var.$border__main
            border-color: var.$color__grey
            overflow: hidden

            & > h2
                max-height: 100%
                width: 100%
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis

        &--header-price
            padding:
                left: var.$padding__m
                right: var.$padding__l

        &--header-title,
        &--header-price

            & > p
                margin-bottom: var.$gap__xxs

        &--menu
            position: sticky
            top: calc(#{desktopVar.$header} + #{var.$padding__m})
            height: max-content
            padding: var.$padding__xs var.$padding__s var.$padding__s
            background-color: var.$color__light-grey

            & > div > .auction__main-content--button-wrapper
                overflow: hidden
                transition: height var.$transition__main

                & > p,
                & > button
                    margin-top: var.$padding__s

                & > p:first-child
                    margin-left: unset
                    margin:
                        top: var.$gap__xl

                & > button
                    width: 100%

        &--menu-item
            $triangle-main: .75rem
            $triangle-secondary: .5rem
            position: relative

            & > button
                width: 100%
                text-align: left
                padding:
                    top: var.$padding__xs
                    bottom: var.$padding__xs
                border-bottom: var.$border__main var.$color__grey
                transition: color var.$transition__main

                &:disabled
                    color: var.$color__white
                    cursor: not-allowed

                &:disabled ~ span
                    width: calc(100% + #{var.$padding__s} * 2 + #{$triangle-main})

            & > span
                position: absolute
                @include mixin.pin(0, auto, 1px, -#{var.$padding__s})
                display: grid
                grid-template-columns: 1fr $triangle-main
                align-items: center
                height: calc(100% - 1px)
                width: 0
                overflow: hidden
                transition: width var.$transition__main
                z-index: -1

                &::before,
                &::after
                    content: ""
                    display: block

                &::before
                    height: 100%
                    width: 100%
                    background-color: var.$color__black

                &::after
                    border:
                        top: $triangle-secondary solid transparent
                        bottom: $triangle-secondary solid transparent
                        left: $triangle-main solid var.$color__black

            &:hover

                & > button
                    color: var.$color__white

                & > span
                    width: calc(100% + #{var.$padding__s} * 2 + #{$triangle-main})

        &--data-list
            display: grid
            grid-template-columns: repeat(2, 1fr)
            row-gap: var.$gap__l
            overflow: hidden
            transition: height var.$transition__main

            & > li:first-child,
            & > li:last-child
                grid-column: 1 / 3

            & > li:nth-child(2),
            & > li:nth-child(4)
                padding-right: calc(#{var.$gap__l} / 2)

            & > li:nth-child(3),
            & > li:nth-child(5)
                padding-left: calc(#{var.$gap__l} / 2)

        &--data-price
            position: relative
            padding:
                top: var.$gap__l
                bottom: var.$gap__l
            border:
                top: var.$border__main var.$color__grey
                bottom: var.$border__main var.$color__grey

        &--details-annotation
            border: var.$border__main var.$color__orange
            margin-top: var.$padding__m
            padding: var.$padding__xs var.$padding__s

            & > h4:nth-of-type(2)
                margin-top: var.$gap__l

            li
                margin-bottom: var.$gap__xxs

                &:last-child
                    margin-bottom: unset

        &--downloads
            margin-top: var.$gap__l

            & > li
                border-top: var.$border__main var.$color__grey

                &:last-child
                    border-bottom: var.$border__main var.$color__grey

        &--download-link
            display: grid
            grid-template-columns: auto 1fr auto
            padding: var.$gap__xs var.$gap__s
            column-gap: var.$gap__m
            align-items: center
            transition: var.$transition__main

            & > i
                font-size: 1.25rem

            &:hover
                background-color: var.$color__light-grey

                & > .button__light-grey
                    background-color: var.$color__black
                    color: var.$color__white

        &--history
            margin-bottom: unset

            th, td
                border: unset
                padding: var.$gap__m var.$gap__s

        &--history-header > tr > th
            text-align: left
            padding-bottom: var.$gap__xl

            &:first-child
                width: 67%

        &--history-content-header > th
            border-bottom: var.$border__main var.$color__grey

        &--history-content
            text-align: center

            &:nth-child(odd)
                background-color: var.$color__light-grey

            &.my-bid
                background-color: var.$color__light-orange

    &__form-container
        position: fixed
        inset: 0
        display: grid
        grid-template-columns: auto 1fr
        width: 100%
        height: 100%
        background-color: var.$color__white
        overflow: auto
        transition: var.$transition__slow
        z-index: 500

        & > button,
        & > form
            grid-column: 1 / 2
            grid-row: 1 / 2

        &--summary
            display: grid
            grid-template-columns: repeat(2, 1fr)
            column-gap: var.$gap__m
            row-gap: var.$gap__l
            height: fit-content
            padding: var.$padding__l

        &--summary-header,
        &--summary-annotation,
        .auction__main-content--history
            grid-column: 1 / 3

        &--summary-header > p
            margin-bottom: var.$gap__xxs

        &--summary-best-bid
            grid-column: 1 / 2

        &--summary-date
            grid-column: 2 / 3

            & > p
                font-weight: 600
                font-size: 1.25rem
                line-height: 1.25

        &--summary-best-bid > h4,
        &--summary-date > h4
            margin-bottom: var.$gap__xxs

    &__form
        box-sizing: content-box
        position: sticky
        top: 0
        height: 100vh
        width: calc(#{var.$el-size__xxl} * 2)
        background-color: var.$color__light-grey
        padding:
            left: var.$padding__l
            right: var.$padding__l
        overflow: auto

        &--return
            width: 100%
            padding-top: var.$padding__m
            margin-bottom: var.$padding__l
            text-align: left
            transition: color var.$transition__main

            & > i
                @include mixin.flex(center, center, row)
                height: var.$el-size__s
                width: var.$el-size__s
                border: var.$border__main var.$color__black
                border-radius: 50%
                transition: border-color var.$transition__main

            &:hover
                color: var.$color__orange

                & > i
                    border-color: var.$color__orange

        &--inputs
            display: grid
            row-gap: var.$gap__l
            padding:
                left: 1px
                right: 1px
                bottom: var.$padding__l
            overflow: hidden
            transition: height var.$transition__main

        &--bid

            .input-label__main--input-icon

                &::before
                    content: ""
                    display: block
                    position: absolute
                    inset: 0
                    height: 100%
                    width: 100%
                    background-color: var.$color__white
                    z-index: 0

                & > input
                    position: relative
                    z-index: 1

                & > i
                    position: absolute
                    font-size: .75rem
                    z-index: 2

            & > p
                padding:
                    left: var.$padding__xxs
                    right: var.$padding__xxs

        .list__main > .auction__form--me-as-bidder
            border-color: var.$color__dark-grey

        &--terms
            display: grid
            grid-template-columns: auto 1fr
            margin-top: var.$gap__xl

            .input-error__wrapper
                grid-column: 1 / 3
                grid-row: 2 / 3
                text-align: left

            .input-error__message
                display: inline-block
                margin-top: var.$gap__xs

        &--confirm,
        &--confirm-resume
            display: grid
            grid-template-columns: 1fr

        &--confirm
            row-gap: var.$gap__xl
            overflow: hidden
            transition: height var.$transition__main

            & > p
                margin-top: var.$gap__xl

        &--confirm-resume
            row-gap: var.$gap__s
            padding: var.$padding__xs
            border: var.$border__main var.$color__orange

        &--confirm-buttons
            display: grid
            grid-template-columns: repeat(2, 1fr)
            column-gap: var.$gap__l

        &--price-preview
            display: inline-flex

            & > span
                display: inline-block
                overflow: hidden
                transition: height var.$transition__main

    &__annotation
        padding:
            left: var.$padding__l
            right: var.$padding__l

        &--content
            margin-top: var.$padding__s
            padding: var.$padding__s var.$padding__m
            border: var.$border__main var.$color__orange

            & > ol
                list-style: none
                padding: unset
                margin: unset
                columns: 2
                column-gap: var.$gap__l

                h3
                    margin-bottom: var.$gap__xxs

                & > li
                    margin-bottom: var.$gap__m
