@font-face
    font-family: "text"
    src: url("../fonts/NunitoSans-Regular.woff2") format("woff2")
    font-weight: 400
    font-style: normal

@font-face
    font-family: "text"
    src: url("../fonts/NunitoSans-Light.woff2") format("woff2")
    font-weight: 300
    font-style: normal

@font-face
    font-family: "text"
    src: url("../fonts/NunitoSans-LightItalic.woff2") format("woff2")
    font-weight: 300
    font-style: italic

@font-face
    font-family: "text"
    src: url("../fonts/NunitoSans-SemiBold.woff2") format("woff2")
    font-weight: 600
    font-style: normal

@font-face
    font-family: "text"
    src: url("../fonts/NunitoSans-ExtraBold.woff2") format("woff2")
    font-weight: 800
    font-style: normal

@font-face
    font-family: "icons"
    src: url("../fonts/font-icons.woff2") format("woff2")
    font-weight: normal
    font-style: normal

@font-face
    font-family: "email"
    src: url("../fonts/email.woff2") format("woff2")
    font-weight: normal
    font-style: normal

@font-face
    font-family: "email-kualitte"
    src: url("../fonts/email-kualitte.woff2") format("woff2")
    font-weight: normal
    font-style: normal
