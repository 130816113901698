@use "../abstract/variables" as var

body
    color: var.$color__black
    font-family: "text", sans-serif
    font-weight: 400
    font-style: normal
    font-size: .9rem
    line-height: 1.25

.expand-open
    transform: scaleY(-1)

.opacity-none
    opacity: 0

.container__slim
    max-width: 700px
    margin:
        left: auto
        right: auto

.padding-bottom-l
    padding-bottom: var.$padding__l

.display-block
    display: block

.container__transition
    overflow: hidden
    transition: height var.$transition__main
