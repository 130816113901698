@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.verify-password
    width: calc(#{var.$el-size__l} * 6 + #{var.$gap__xs} * 5 + #{var.$padding__m} * 2 + 2px)
    @include mixin.centerX

    &__title
        padding:
            left: var.$padding__m
            right: var.$padding__m
        margin-bottom: var.$gap__xl

    &__form
        display: grid
        grid-auto-flow: row
        row-gap: var.$gap__xxxl

    &__inputs
        border: var.$border__main var.$color__orange
        padding: var.$padding__m

    &__resend
        padding:
            left: var.$padding__m
            right: var.$padding__m
        margin-top: var.$gap__l

    &__submit
        box-sizing: border-box
        width: 100%
        padding:
            left: var.$padding__m
            right: var.$padding__m

        & >  button
            width: 100%
            max-width: var.$el-size__xxxl
            @include mixin.centerX
