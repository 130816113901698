@use "../abstract/variables" as var
@use "../abstract/mixins" as mixin

.button__solid-blue,
.button__white,
.button__black,
.button__border
    @include mixin.flex(center, center, row)
    box-sizing: border-box
    height: var.$el-size__xl
    min-width: var.$el-size__xl
    padding:
        left: var.$padding__m
        right: var.$padding__m
    transition: var.$transition__main

.button__solid-blue,
.button__black,
.button__border
    text-transform: uppercase
    font-weight: 800
    font-size: .9rem

.button__solid-blue,
.button__border
    color: var.$color__black

.button__solid-blue
    background-color: var.$color__pale-orange

    &:hover
        background-color: var.$color__orange

    &:active
        box-shadow: var.$shadow__main-inset

    &:disabled
        cursor: not-allowed
        opacity: .5

        &:hover
            background-color: var.$color__pale-orange

        &:active
            box-shadow: unset

.button__white
    background-color: var.$color__white
    box-shadow: var.$shadow__main
    font-weight: 400
    font-size: .9rem

    &:hover
        background-color: var.$color__black
        color: var.$color__white

    &:active
        box-shadow: var.$shadow__main-hidden, var.$shadow__main-inset-dark
        background-color: var.$color__dark-grey

    &:disabled
        cursor: not-allowed
        opacity: .5

        &:hover
            background-color: var.$color__white

        &:active
            box-shadow: unset
            background-color: var.$color__white

    &:hover
        background-color: var.$color__grey

    &:active
        box-shadow: var.$shadow__main-inset

    &:disabled
        cursor: not-allowed
        opacity: .5

        &:hover
            background-color: var.$color__light-grey

        &:active
            box-shadow: unset

.button__border,
.button__border-small
    border: var.$border__main var.$color__black

    &:hover
        border-color: var.$color__orange
        color: var.$color__orange

.button__black
    color: var.$color__white
    background-color: var.$color__black

    &:hover
        background-color: var.$color__dark-grey

    &:active
        box-shadow: var.$shadow__main-hidden, var.$shadow__main-inset-dark

.button__small,
.button__light-grey,
.button__light-grey-hover
    box-sizing: border-box
    height: var.$el-size__s
    font-weight: 400
    font-size: .8rem
    padding:
        left: var.$padding__xs
        right: var.$padding__xs
    transition: var.$transition__main

.button__small,
.button__light-grey
    @include mixin.flex(center, center, row)

.button__small
    &:hover
        background-color: var.$color__light-orange

    &:active
        box-shadow: var.$shadow__small-inset

.button__light-grey
    background-color: var.$color__light-grey

    &:hover
        background-color: var.$color__black
        color: var.$color__white

    &:active
        background-color: var.$color__dark-grey
        box-shadow: var.$shadow__small-inset-dark

.button__light-grey-hover
    position: relative
    @include mixin.flex(flex-start, center, row)

    &::before
        content: ""
        display: block
        position: absolute
        @include mixin.pin(0, auto, 0, 0)
        width: 0
        height: 100%
        background-color: var.$color__light-grey
        transition: width var.$transition__main
        z-index: -1

    &:hover::before
        width: 100%

    &:disabled
        background-color: var.$color__light-grey
        cursor: not-allowed

.button__content-grid
    display: inline-grid
    grid-template-columns: repeat(2, auto)
    column-gap: var.$gap__s

.button-close
    @include mixin.buttonClose

.button-delete,
.button__border-small
    padding: var.$padding__xxxs var.$padding__xs
    transition: var.$transition__main

.button-delete
    background-color: var.$color__light-red
    transition-property: background-color

    &:hover
        background-color: var.$color__red
