@use "./variables" as var
@use "./mixins" as mixin

// VUE transitions
.fade-enter-active, .fade-enter
    opacity: 1

.fade-leave-active, .fade-leave-to
    opacity: 0

.bounce-enter-active, .bounce-enter,
.bounce-down-enter-active, .bounce-down-enter
    @include mixin.animation(bounceFromDown, .5s, forwards)

.bounce-leave-active, .bounce-leave-to
    @include mixin.animation(bounceUp, .5s, forwards)

.bounce-down-leave-active, .bounce-down-leave-to
    @include mixin.animation(bounceToDown, .5s, forwards)

.translate-enter-active, .translate-enter
    @include mixin.animation(opacityAni, .5s, forwards)

.translate-leave-active, .translate-leave-to
    @include mixin.animation(translate-left, .5s, forwards)

@media screen and (max-width: var.$common-breakpoint__m)
    .translate-leave-active, .translate-leave-to
        @include mixin.animation(translate-down, .5s, forwards)

.submit-message-leave-active,
.submit-message-enter-active,
.submit-message-leave-to
    transform: translateY(105%)

.filter-right-left-enter-active, .filter-right-left-enter
    @include mixin.animation(openMenuFromRight, .5s, forwards)

.filter-right-left-leave-active, .filter-right-left-leave-to
    @include mixin.animation(closeMenuToLeft, .5s, forwards)

.filter-left-right-enter-active, .filter-left-right-enter
    @include mixin.animation(openMenuFromLeft, .5s, forwards)

.filter-left-right-leave-active, .filter-left-right-leave-to
    @include mixin.animation(closeMenuToRight, .5s, forwards)

// Simple transitions
.slide-up
    @include mixin.animation(slideUp, .5s, forwards)

.slide-down
    @include mixin.animation(slideDown, .5s, forwards)
