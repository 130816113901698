@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.submit-result
    position: fixed
    @include mixin.pin(auto, 0, 0, 0)
    padding: var.$padding__m var.$padding__l
    transition: var.$transition__main
    z-index: 1000

    &__content-wrapper
        display: block

    &__content
        display: flex
        box-sizing: border-box
        width: max-content
        padding: var.$padding__m
        box-shadow: var.$shadow__main

    &__success
        background-color: var.$color__black

    &__failure
        background-color: var.$color__red
