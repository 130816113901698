body,
h1,
h2,
h3,
h4,
p,
ul
    margin: unset

a
    color: inherit
    text-decoration: none

ul
    padding: unset
    list-style: none

button
    margin: unset
    padding: unset
    appearance: none
    -moz-appearance: none
    -webkit-appearance: none
    background-color: unset
    border: unset
    font-family: "text", sans-serif
    color: unset

    &:hover,
    &:active,
    &:disabled
        color: unset

input,
fieldset,
textarea
    border: unset
    padding: unset

// remove default arrows for Mozilla
input[type=number]
    -moz-appearance: textfield
    appearance: textfield
    margin: 0

// remove default arrows for Webkit
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0

input[type=search]
    background-color: unset

details > summary
    border: none
    list-style: none

    &::-webkit-details-marker
        display: none

figure
    margin: unset

hr
    border: unset
    height: 0

sup
    z-index: -1

.swiper-pagination-lock,
.swiper-button-lock
    display: initial
    
[role="button"]:focus
    outline: unset
