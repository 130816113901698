@use "../../main/abstract/variables" as var
@use "../abstract/variables" as desktopVar

.auction-group
    margin-top: calc(#{desktopVar.$header} + #{var.$padding__m})

    &__main
        display: grid
        grid-template-columns: 2fr 3fr
        column-gap: var.$gap__xxl
        padding:
            left: var.$padding__l
            right: var.$padding__l

        &--header
            margin-top: var.$padding__l
            padding:
                top: var.$padding__m
                bottom: var.$padding__m

            & > h1
                margin-bottom: var.$gap__s

            & > p:last-child
                display: inline-grid
                grid-template-columns: auto 1fr
                column-gap: var.$gap__s
                margin-top: var.$gap__xl

                & > i
                    font-size: 2rem

        &--img
            display: grid
            grid-template-columns: 2fr repeat(2, 1fr)
            grid-template-rows: var.$padding__l 1fr var.$padding__l

            & > img
                grid-column: 1 / 3
                grid-row: 2 / 3
                height: auto
                width: 100%

            & > span
                display: block
                position: relative
                grid-column: 2 / 4
                grid-row: 1 / 4

                &::after
                    content: ""
                    display: block
                    position: absolute
                    inset: 0 auto 0 0
                    height: 100%
                    width: calc(100% + #{var.$padding__l})
                    background-color: var.$color__black
                    z-index: -1

    &__properties
        margin:
            top: var.$padding__xl
            bottom: var.$padding__xl

        &--header
            margin-bottom: var.$padding__l
            padding:
                left: var.$padding__m
                right: var.$padding__m
            text-align: center
