@use "../../main/abstract/variables" as var

.direct-sells-single

    &__title
        margin-top: var.$gap__m

    &__file-list-wrapper
        display: block
        transition: height var.$transition__main

        .input-label__file--list
            margin:
                top: var.$gap__m
                bottom: var.$gap__m
            border-bottom: var.$border__main var.$color__grey

    .auction__main-content--data-list

        & > li:first-child,
        & > li:nth-child(2)
            grid-column: 1 / 3

        & > li:first-child,
        & > li:nth-child(2),
        & > li:last-child
            padding:
                left: var.$padding__xxxs
                right: var.$padding__xxxs

    .auction__form-container--summary
        grid-template-columns: 1fr

    .auction__form-container--summary-date
        grid-column: 1 / 2

    .input-label__file--input
        background-color: var.$color__white
        border-color: var.$color__grey

        &:hover
            border-color: var.$color__orange
