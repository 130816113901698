@use "../abstract/mixins" as mixin

.icon__close
    @include mixin.icon('\eA0A')

.icon__download
    @include mixin.icon('\eA0B')

.icon__expand
    @include mixin.icon('\eA0C')

.icon__filter-arrows
    @include mixin.icon('\eA0D')

.icon__filter
    @include mixin.icon('\eA0E')

.icon__left
    @include mixin.icon('\eA0F')

.icon__box-checked
    @include mixin.icon('\eA01')

.icon__box
    @include mixin.icon('\eA02')

.icon__circle-clock
    @include mixin.icon('\eA03')

.icon__circle-euro
    @include mixin.icon('\eA04')

.icon__euro
    @include mixin.icon('\eA19')

.icon__circle-left
    @include mixin.icon('\eA05')

.icon__circle-location
    @include mixin.icon('\eA06')

.icon__circle-property
    @include mixin.icon('\eA07')

.icon__circle-right
    @include mixin.icon('\eA08')

.icon__clock
    @include mixin.icon('\eA09')

.icon__menu
    @include mixin.icon('\eA10')

.icon__property
    @include mixin.icon('\eA11')

.icon__province
    @include mixin.icon('\eA12')

.icon__right
    @include mixin.icon('\eA13')

.icon__search
    @include mixin.icon('\eA14')

.icon__location
    @include mixin.icon('\eA15')

.icon__show
    @include mixin.icon('\eA16')

.icon__hide
    @include mixin.icon('\eA17')

.icon__info
    @include mixin.icon('\eA18')

.icon__filter-asc
    @include mixin.icon('\eA21')

.icon__filter-desc
    @include mixin.icon('\eA20')

.icon__my-data
    @include mixin.icon('\eA22')

.icon__clients
    @include mixin.icon('\eA23')

.icon__all
    @include mixin.icon('\eA24')

.icon__motoring
    @include mixin.icon('\eA25')

.icon__industry
    @include mixin.icon('\eA26')

.icon__stocks
    @include mixin.icon('\eA27')

.icon__building
    @include mixin.icon('\eA28')

.icon__other
    @include mixin.icon('\eA29')

.icon__email-old
    @include mixin.icon('\e818')
    font-family: "email", sans-serif

.icon__email
    @include mixin.icon('\e819')
    font-family: "email-kualitte", sans-serif
