@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.message
    @include mixin.darkTransparentBg
    padding: calc(#{var.$padding__l} + #{var.$padding__m} * 2)

    &__container
        position: relative
        @include mixin.centerX
        @include mixin.centerY
        display: grid
        grid-auto-flow: row
        row-gap: var.$gap__m
        width: 100%
        max-width: var.$el-size__queen
        padding: var.$padding__m
        background-color: var.$color__white
        z-index: 2

        & > button
            width: 100%
            max-width: var.$el-size__xxl
            margin:
                top: var.$gap__l
                left: auto

    &__buttons,
    &__button
        display: grid
        width: max-content
        margin:
            left: auto
            top: var.$padding__m

    &__buttons
        grid-template-columns: repeat(2, 1fr)
        column-gap: var.$gap__m

    &__button
        grid-template-columns: auto

    &__close-bg
        @include mixin.closeBg
