@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.auction-img
    display: grid
    grid-template-columns: 2fr repeat(2, 1fr)
    grid-template-rows: var.$padding__l 1fr var.$padding__l

    &__swiper
        grid-column: 1 / 3
        grid-row: 2 / 3
        height: auto
        width: 100%
        padding-right: var.$padding__xl

    &__item
        display: flex
        align-items: center

    &__swiper-controls
        position: absolute
        @include mixin.pin(-1px, 0, -1px, auto)
        box-sizing: border-box
        @include mixin.flex(center, flex-end, column)
        width: calc(#{var.$padding__xl} + 1px)
        height: calc(100% + 2px)
        color: var.$color__white
        background-color: var.$color__black
        z-index: 2

        & > .swiper-button-disabled
            cursor: not-allowed
            color: var.$color__dark-grey

            &:hover,
            &:active
                color: var.$color__dark-grey

    &__prev,
    &__next
        display: flex
        height: 2.5rem
        font-size: 2.5rem
        cursor: pointer
        transition: color var.$transition__main

        &:hover
            color: var.$color__orange

        &:active
            color: var.$color__grey

    &__prev
        margin:
            top: auto
            bottom: calc(#{var.$gap__l} / 2)

    &__next
        margin:
            top: calc(#{var.$gap__l} / 2)
            bottom: auto

    &__pagination
        text-align: right
        font-weight: 300

        & > .swiper-pagination-current
            font-size: 1.75rem
            font-weight: 600

    & > span
        display: block
        position: relative
        grid-column: 2 / 4
        grid-row: 1 / 4

        &::after
            content: ""
            display: block
            position: absolute
            inset: 0 auto 0 0
            height: 100%
            width: calc(100% + #{var.$padding__l})
            background-color: var.$color__black
            z-index: -1
