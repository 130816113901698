@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.auction-preview
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: auto

    &__container
        grid-column: 1 / 2
        grid-row: 1 / 2
        display: grid
        grid-template-columns: 1fr calc(#{var.$el-size__xl} + #{var.$swiper-item__m} / 2) 9fr

        &::before
            content: ""
            display: block
            grid-column: 1 / 3
            grid-row: 1 / 2
            height: 100%
            background-color: var.$color__black

    &__swiper
        grid-column: 2 / 4
        grid-row: 1 / 2
        box-sizing: border-box
        width: 100%
        padding:
            left: var.$el-size__xl
            top: var.$padding__l
            bottom: var.$padding__l
            right: var.$padding__m

    &__item
        height: auto
        width: 100%
        max-width: var.$swiper-item__m
        margin-right: var.$gap__xl

        & > a,
        & > button
            box-sizing: border-box
            display: block
            width: 100%
            height: 100%
            padding: var.$padding__s
            background-color: var.$color__white
            box-shadow: var.$shadow__main
            border: var.$border__thin var.$color__white
            transition: var.$transition__main

            &:hover
                box-shadow: var.$shadow__main-hidden
                color: var.$color__white
                background-color: var.$color__black
                border-color: var.$color__dark-grey

            &:active
                box-shadow: var.$shadow__main-inset-dark
                color: var.$color__white
                background-color: var.$color__dark-grey

            & > .auction-preview__group-title
                margin-bottom: var.$gap__xl

                & > p,
                .auction-preview__resume,
                .auction-preview__resume > p:not(p:first-child)
                    margin-top: var.$gap__xs

            .auction-preview__resume
                display: block

                strong
                    font-weight: 800

    &__item-img
        width: 100%
        margin-bottom: var.$padding__m

        & > img
            width: inherit
            height: var.$el-size__xxxl
            object-fit: cover
            object-position: center
            margin-bottom: var.$gap__s

        & > figcaption
            text-align: left

    &__swiper-controls
        position: absolute
        @include mixin.pin(0, auto, 0, 0)
        box-sizing: border-box
        display: flex
        flex-flow: column
        width: var.$el-size__xl
        height: 100%
        padding:
            top: var.$padding__l
            bottom: var.$padding__l
        color: var.$color__white
        background-color: var.$color__black
        z-index: 2

        & > .swiper-button-disabled
            cursor: not-allowed
            color: var.$color__dark-grey

            &:hover,
            &:active
                color: var.$color__dark-grey

    &__prev,
    &__next
        display: flex
        height: 2.5rem
        font-size: 2.5rem
        cursor: pointer
        transition: color var.$transition__main

        &:hover
            color: var.$color__orange

        &:active
            color: var.$color__grey

    &__prev
        margin:
            top: auto
            bottom: calc(#{var.$gap__l} / 2)

    &__next
        margin:
            top: calc(#{var.$gap__l} / 2)
            bottom: auto

    &__pagination
        font-weight: 300

        & > .swiper-pagination-current
            font-size: 1.75rem
            font-weight: 600

    &__empty
        box-sizing: border-box
        max-width: var.$swiper-item__m
        padding: var.$padding__s
        background-color: var.$color__white
        box-shadow: var.$shadow__main

        &--content

            & > h2
                text-transform: uppercase

            & > p:first-of-type
                margin:
                    top: var.$gap__xl
                    bottom: var.$gap__xs

        & > a
            margin-top: var.$gap__xxl
