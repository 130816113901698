// =======
// Global variables
// =======

// COLORS
$color__white: #FFFFFF
$color__black: #1C2530
$color__dark-grey: #344955
$color__medium-grey: #A1A8AF
$color__grey: #C2C8CC
$color__pale-grey: #EDF0F2
$color__light-grey: #EDF0F2
$color__orange: #F9AA33
$color__pale-orange: #F7B661
$color__light-orange: #FFF2DB
$color__red: #F76161
$color__light-red: #FFDBDB

// SIZES
// padding
$padding__xxl: 10rem
$padding__xl: 6rem
$padding__l: 4rem
$padding__m: 2rem
$padding__s: 1.5rem
$padding__xs: 1rem
$padding__xxs: .75rem
$padding__xxxs: .5rem
// space between
$gap__xxxl: 6rem
$gap__xxl: 4rem
$gap__xl: 2rem
$gap__l: 1.5rem
$gap__m: 1rem
$gap__s: .75rem
$gap__xs: .5rem
$gap__xxs: .25rem
// element sizes
$el-size__queen: 25rem
$el-size__xxxl: 15rem
$el-size__xxl: 10rem
$el-size__xl: 4.5rem
$el-size__l: 3.75rem
$el-size__m: 3rem
$el-size__s: 2.25rem
$el-size__xs: 1.5rem
$el-size__xxs: 1.25rem
$el-size__xxxs: .75rem
// button sizes
$button-size__m: 3.25rem
$button-size__s: 2.75rem
// input height
$search-height: 4.25rem
// carousel element sizes
$swiper-item__m: 21rem
// button sizes
$button-password: 2.75rem

// BORDERS
$border__main: 1px solid
$border__thick: 2px solid
$border__thin: .5px solid

// SHADOWS
$shadow__main: 0 0 2rem rgba($color__black, .25)
$shadow__main-hidden: 0 0 2rem rgba($color__black, 0)
$shadow__main-inset: inset .2rem .2rem .4rem rgba($color__black, .25)
$shadow__main-inset-hidden: inset .2rem .2rem .4rem rgba($color__black, 0)
$shadow__main-inset-dark: inset .2rem .2rem .4rem rgba($color__black, 1)
$shadow__small-inset: inset .1rem .1rem .25rem rgba($color__black, .25)
$shadow__small-inset-dark: inset .1rem .1rem .25rem rgba($color__black, 1)

// TRANSITIONS
$transition__main: .3s ease-in-out
$transition__slow: .5s ease-in-out

// BREAKPOINTS
$common-breakpoint__s: 500px
$common-breakpoint__m: 900px
