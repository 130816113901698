@use "../../main/abstract/variables" as var

.faqs
    padding:
        top: var.$padding__l
        left: var.$padding__l
        right: var.$padding__l

    & > h1
        margin-bottom: var.$padding__l
        text-align: center

    &__content
        display: grid
        row-gap: var.$gap__xl

        &--item
            display: grid
            row-gap: var.$gap__xxs
